import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store, { RootState } from '../../store'
import useTWSMapContext from '../../hooks/UseTWSContext'
import CameraNetwork from '../CameraNetwork/CameraNetwork'
import VerticalProfile from '../VerticalProfile/VerticalProfile'
import {
  WMS80MWinds,
  WMSSurfaceWinds,
  WMSTemperature,
  WMSVisibility,
  WMSCeiling,
  WMSSurfaceWindsCA,
  WMS80MWindsCA,
  WMS80MWindsUK,
  WMSSurfaceWindsUK,
  WMSTemperatureUK,
  WMSVisibilityUK,
  WMSCeilingUK,
  WMSWindGust,
  WMSWindGustUK,
  WMSTemperatureCA,
  WMSWindGustCA,
  WMSVisibilityCA,
  WMSCeilingCA,
  WMS80MWindsBR,
  WMSTemperatureBR,
  WMSSurfaceWindsBR,
  WMSVisibilityBR,
  WMSWindGustBR,
  WMSCeilingBR
} from '../WMSLayerComponents/WMSWeatherLayers'
import { toggleMissionCast } from '../MissionCAST/MissionCASTSlice'
import { showAddLocationPopup } from '../Users/UserSlice'
import {
  SatelliteComponent,
  SectionalChartComponent,
} from '../../map/BaseLayers'
import { setActiveBaseLayer } from '../../map/mapSlice'
import {
  hideAlertSETTING,
  setLocation,
  setLocationType,
  showAlertSETTING,
  showAlertSETTINGCreate,
} from '../Alerting/AlertingSlice/AlertingSlice'
import { toggleRouteCast } from '../DecisionProducts/RouteCast/routecastSlice'
import RouteCAST from '../DecisionProducts/RouteCast/RouteCAST'
import { setCurLayer } from '../WetdogWeatherProducts/WetDogWeatherProductsSlice'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { WetDogWeatherLayersEnum } from '../../interfaces/WetDogWeatherLayers'
import { WMSLayer } from '../WMSLayerComponents/WMSLayer'
import { RouteCASTDraw } from '../DecisionProducts/RouteCast/RouteCASTDraw'
import AddNewLocation from '../../Components/Users/UserLocation/AddNewLocation'
import { VerticalProfileAPI } from '../../services/SurfaceObsAPI'
import {
  deselectMenuItemById,
  setSelectedItemIds,
} from '../../menus/SideNavigation/SideNavigationSlice'
import { AlertListBox } from '../Alerting/AlertListBox/AlertListBox'
// Add other imports for additional components as needed

function MenuSelection() {
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const selection = useSelector(
    (state: RootState) => state.sideNav.selectedMenuItem
  )
  const selectedItems = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const selectedItemIds = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const { addToTWS, removeFromTWS, twsElements } = useTWSMapContext()
  const { id } = selection !== null ? selection : { id: null }
  const baseLayerUtils = useSelector(
    (state: RootState) => state.mapState.baseLayerUtil
  )
  const dispatch = useDispatch()
  const [currTile, setCurrTile] = useState<string>('')
  const [currTileName, setCurrTileName] = useState<string>('')
  const currentLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const wmsOpacity = 0.4

  const eventCount = useSelector((state) => state.alert.eventCount)
  const errorStatus = useErrorStatus()

  const tileProducts = [
    'observations-myradar',
    'observations-mrms-radar',
    // 'decision-missioncast',
    // 'camera-network',
    'aviation-winds-80m',
    'aviation-temperature',
    'aviation-visibility',
    'aviation-surface-winds',
    'aviation-ceiling',
    'aviation-wind-gust',
    'aviation-temperature-ca',
    'aviation-ceiling-ca',
    'aviation-visibility-ca',
    'aviation-windgust-ca',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-winds-80m-uk',
    'aviation-surface-winds-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'aviation-wind-gust-uk',
    'aviation-ceiling-ce',
    'aviation-visibility-ce',
    'aviation-temperature-ce',
    'aviation-surface-winds-ce',
    'aviation-winds-80m-ce',
    'aviation-wind-gust-ce',
    'aviation-temperature-br',
    'aviation-ceiling-br',
    'aviation-visibility-br',
    'aviation-windgust-br',
    'aviation-surface-winds-br',
    'aviation-winds-80m-br',
    'aviation-ceiling-middle-east',
    'aviation-visibility-middle-east',
    'aviation-temperature-middle-east',
    'aviation-surface-winds-middle-east',
    'aviation-winds-80m-middle-east',
    'aviation-wind-gust-middle-east',
    'aviation-wafs-icing',
    'aviation-wafs-turbulence',
    'aerox-menu',
  ]

  useEffect(() => {
    // the location has changed so remove the camera network layer then add the new one
    if (selectedItemIds.includes('camera-network')) {
      removeFromTWS('camera-network')
      addToTWS(
        <CameraNetwork
          key={'camera-network'}
          position={[currentLocation.latitude, currentLocation.longitude]} //[47.925278, -97.0325]
        />
      )
    }

    const vprof = VerticalProfileAPI(currentLocation)
    vprof.then((data) => {
      if (data?.message === 'Vertical Profile(s) Available') {
        dispatch(setSelectedItemIds(['vertical-profile']))
      } else {
        dispatch(deselectMenuItemById('vertical-profile'))
        // removeFromTWS('vertical-profile')
        console.log('No Vertical Profile available')
      }
    })
  }, [currentLocation])

  useEffect(() => {
    const newActiveLayer = baseLayerUtils[1]
    const oldActiveLayer = baseLayerUtils[0]
    switch (newActiveLayer) {
      case 'maps-road':
        if (oldActiveLayer === 'maps-satellite') {
          removeFromTWS('maps-satellite')
          dispatch(setActiveBaseLayer('maps-road'))
        } else if (oldActiveLayer === 'maps-sectional') {
          removeFromTWS('maps-sectional')
          dispatch(setActiveBaseLayer('maps-road'))
        }
        break
      case 'maps-satellite':
        if (oldActiveLayer === 'maps-road') {
          addToTWS(
            <SatelliteComponent
              url={
                'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
              }
              attribution=""
              key="maps-satellite"
              opacity={0.6}
            />
          )
          dispatch(setActiveBaseLayer('maps-satellite'))
        } else if (oldActiveLayer === 'maps-sectional') {
          removeFromTWS('maps-sectional')

          addToTWS(
            <SatelliteComponent
              url={
                'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
              }
              attribution=""
              key="maps-satellite"
              opacity={0.6}
            />
          )
          dispatch(setActiveBaseLayer('maps-satellite'))
        }
        break
      case 'maps-sectional':
        if (oldActiveLayer === 'maps-road') {
          addToTWS(
            <SectionalChartComponent
              key="maps-sectional"
            />
          )
          dispatch(setActiveBaseLayer('maps-sectional'))
        } else if (oldActiveLayer === 'maps-satellite') {
          removeFromTWS('maps-satellite')
          addToTWS(
            <SectionalChartComponent
              key="maps-sectional"
            />
          )
          dispatch(setActiveBaseLayer('maps-sectional'))
        }
        break
      default:
        break
    }
  }, [baseLayerUtils])

  const persistantProducts = [
    // these items need to not change when switching between
    // normal and dark mode.
    'observations-myradar',
    'observations-mrms-radar',
    'aviation-ceiling',
    'aviation-visibility',
    'aviation-temperature',
    'aviation-surface-winds',
    'aviation-winds-80m',
    'aviation-wind-gust',
    'aviation-temperature-ca',
    'aviation-ceiling-ca',
    'aviation-visibility-ca',
    'aviation-windgust-ca',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-surface-winds-uk',
    'aviation-winds-80m-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'aviation-wind-gust-uk',
    'forecast-aviation-ceiling',
    'forecast-aviation-visibility',
    'forecast-aviation-temperature',
    'forecast-aviation-surface-winds',
    'forecast-aviation-winds-80m',
    'forecast-aviation-wind-gust',
    'aviation-ceiling-ce',
    'aviation-visibility-ce',
    'aviation-temperature-ce',
    'aviation-surface-winds-ce',
    'aviation-winds-80m-ce',
    'aviation-wind-gust-ce',
    'aviation-temperature-br',
    'aviation-ceiling-br',
    'aviation-visibility-br',
    'aviation-windgust-br',
    'aviation-surface-winds-br',
    'aviation-winds-80m-br',
    'aviation-ceiling-middle-east',
    'aviation-visibility-middle-east',
    'aviation-temperature-middle-east',
    'aviation-surface-winds-middle-east',
    'aviation-winds-80m-middle-east',
    'aviation-wind-gust-middle-east',
        'aerox-menu'
  ]

  useEffect(() => {
    if (
      (id == currTile || id == null) &&
      !selectedItems.includes(currTileName)
    ) {
      /**
       * HH - adding checks for weather viz layers so
       * they don't get removed when we want to keep them
       */
      if (Array.isArray(selectedItemIds)) {
        if (
          !selectedItemIds.some((element) =>
            persistantProducts.includes(element)
          )
        ) {
          dispatch(
            setCurLayer({
              layer: -1,
              mode: 'forecast',
            })
          )
          removeFromTWS(currTile)
          setCurrTile('')
          setCurrTileName('')
        }
        if (currTile === 'aerox-menu') {
            removeFromTWS('aerox')
        }
      }
      if(selectedItemIds.includes('vertical-profile') && !('vertical-profile' in twsElements) ){
        addToTWS(<VerticalProfile key={"vertical-profile"}></VerticalProfile>)
      }  
      return
    }

    if (tileProducts.includes(id) && currTile != '') {
      dispatch(
        setCurLayer({
          layer: -1,
          mode: '',
        })
      )
      removeFromTWS(currTile)
    }

    const sel: string = selection !== null ? selection.id : ''
    if (sel.startsWith('maps-')) {
      // processMapSelection(selection.id)
    }

    // Assert that id is a string (since we've already checked that it's not null or undefined)
    const idString = id as string
    switch (idString) {
      case 'camera-network':
        if (selectedItemIds.includes('camera-network')) {
          addToTWS(
            <CameraNetwork
              key={'camera-network'}
              position={[defaultLocation.latitude, defaultLocation.longitude]} //[47.925278, -97.0325]
            />
          )
        }
        break
      case 'aviation-winds-80m':
        addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   mode: 'current',
        //   layer: WetDogWeatherLayersEnum.WIND80M
        // }))
        break
      // Add more cases for other components here
      case 'aviation-temperature':
        addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   mode: 'current',
        //   layer : WetDogWeatherLayersEnum.TEMPERATURE
        // }))
        break
      case 'aviation-visibility':
        addToTWS(<WMSVisibility key={'aviation-visibility'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   mode: 'current',
        //   layer: WetDogWeatherLayersEnum.CLOUDCOVER
        // }))
        break
      case 'aviation-surface-winds':
        addToTWS(
          <WMSSurfaceWinds key={'aviation-surface-winds'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   mode: 'current',
        //   layer: WetDogWeatherLayersEnum.WINDUV
        // }))
        break
      case 'aviation-ceiling':
        addToTWS(<WMSCeiling key={'aviation-ceiling'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   mode: 'current',
        //   layer : WetDogWeatherLayersEnum.CLOUDCEILING
        // }))
        break
      // case 'forecast-aviation-winds-80m':
      //   // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
      //   dispatch(setCurLayer({
      //     mode: 'forecast',
      //     layer: WetDogWeatherLayersEnum.WIND80M
      //   }))
      //   break
      // // Add more cases for other components here
      // case 'forecast-aviation-temperature':
      //   // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.35} />)
      //   dispatch(setCurLayer({
      //     mode: 'forecast',
      //     layer: WetDogWeatherLayersEnum.TEMPERATURE
      //   }))
      //   break
      // case 'forecast-aviation-visibility':
      //   dispatch(setCurLayer({
      //     mode: 'forecast',
      //     layer: WetDogWeatherLayersEnum.CLOUDCOVER
      //   }))
      //   break
      // case 'forecast-aviation-surface-winds':
      //   dispatch(setCurLayer({
      //     mode: 'forecast',
      //     layer: WetDogWeatherLayersEnum.WINDUV
      //   }))
      //   break
      // case 'forecast-aviation-ceiling':
      //   dispatch(setCurLayer({
      //     mode: 'forecast',
      //     layer: WetDogWeatherLayersEnum.CLOUDCEILING
      //   }))
      //   break
      case 'aviation-surface-winds-ca':
        addToTWS(
          <WMSSurfaceWindsCA key={'aviation-surface-winds-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-ca':
        addToTWS(
          <WMS80MWindsCA key={'aviation-winds-80m-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-ca':
        addToTWS(
          <WMSTemperatureCA
            key={'aviation-temperature-ca'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-ca':
        addToTWS(
          <WMSCeilingCA key={'aviation-ceiling-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-ca':
        addToTWS(
          <WMSVisibilityCA
            key={'aviation-visibility-ca'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-windgust-ca':
        addToTWS(
          <WMSWindGustCA key={'aviation-windgust-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-br':
        addToTWS(
          <WMSSurfaceWindsBR
            key={'aviation-surface-winds-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-br':
        addToTWS(
          <WMS80MWindsBR key={'aviation-winds-80m-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-br':
        addToTWS(
          <WMSTemperatureBR
            key={'aviation-temperature-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-br':
        addToTWS(
          <WMSCeilingBR key={'aviation-ceiling-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-br':
        addToTWS(
          <WMSVisibilityBR
            key={'aviation-visibility-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-windgust-br':
        addToTWS(
          <WMSWindGustBR key={'aviation-windgust-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-ca':
        addToTWS(
          <WMSTemperatureCA
            key={'aviation-temperature-ca'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-ca':
        addToTWS(
          <WMSCeilingCA key={'aviation-ceiling-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-ca':
        addToTWS(
          <WMSVisibilityCA
            key={'aviation-visibility-ca'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-windgust-ca':
        addToTWS(
          <WMSWindGustCA key={'aviation-windgust-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-br':
        addToTWS(
          <WMSSurfaceWindsBR
            key={'aviation-surface-winds-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-br':
        addToTWS(
          <WMS80MWindsBR key={'aviation-winds-80m-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-br':
        addToTWS(
          <WMSTemperatureBR
            key={'aviation-temperature-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-br':
        addToTWS(
          <WMSCeilingBR key={'aviation-ceiling-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-br':
        addToTWS(
          <WMSVisibilityBR
            key={'aviation-visibility-br'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-windgust-br':
        addToTWS(
          <WMSWindGustBR key={'aviation-windgust-br'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-uk':
        addToTWS(<WMS80MWindsUK key={'aviation-winds-80m-uk'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-uk':
        addToTWS(
          <WMSSurfaceWindsUK key={'aviation-surface-winds-uk'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-uk':
        addToTWS(
          <WMSTemperatureUK key={'aviation-temperature-uk'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-uk':
        addToTWS(
          <WMSVisibilityUK key={'aviation-visibility-uk'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-uk':
        addToTWS(<WMSCeilingUK key={'aviation-ceiling-uk'} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-uk':
        addToTWS(<WMSWindGustUK key={'aviation-wind-gust-uk'} opacity={0.4} />)
        dispatch(toggleMissionCast(false))
      break
      case 'aviation-ceiling-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-ceiling-ce'}
            layerName={'Cloud Ceiling (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-visibility-ce'}
            layerName={'Visibility (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-temperature-ce'}
            layerName={'Temperature (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-surface-winds-ce'}
            layerName={'Surface Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-winds-80m-ce'}
            layerName={'80m Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-wind-gust-ce'}
            layerName={'Wind Gust (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-ceiling-middle-east'}
            layerName={'Cloud Ceiling (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-visibility-middle-east'}
            layerName={'Visibility (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-temperature-middle-east'}
            layerName={'Temperature (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-surface-winds-middle-east'}
            layerName={'Surface Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-winds-80m-middle-east'}
            layerName={'80m Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-wind-gust-middle-east'}
            layerName={'Wind Gust (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'decision-missioncast':
        dispatch(toggleMissionCast(true))
        break
      case 'alerts-create':
        if (selectedItemIds.includes('alerts-create') && !store.getState().sideNav.menuItems[0].subMenu[1].disabled) {
          if (eventCount < 3) {
            dispatch(showAlertSETTING())
            dispatch(setLocation(''))
            dispatch(setLocationType(''))
            dispatch(showAlertSETTINGCreate(true))
          } else {
            errorStatus.addMessage(
              'Event limit reached (Max limit - 3)',
              404,
              'error',
              false
            )
          }
        }
        dispatch(toggleRouteCast(false))
        break
      case 'add-location':
        dispatch(showAddLocationPopup())
        break
      case 'vertical-profile':
        if(selectedItemIds.includes('vertical-profile')){
          addToTWS(<VerticalProfile key={idString}></VerticalProfile>)
        }
        break
      case 'observations-mrms-radar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.MRMS,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case 'observations-myradar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.RADAR,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case 'decision-routcase':
        // dispatch(toggleRouteCast(true))
        dispatch(hideAlertSETTING())
        addToTWS(
          <div
            className="absolute top-0 left-0 w-full"
            key={'decision-routcast'}
          >
            <RouteCAST />
          </div>
        )
        break
      case 'aviation-wind-gust':
        addToTWS(<WMSWindGust key={idString} opacity={wmsOpacity} />)
        dispatch(toggleMissionCast(false))
        // dispatch(setCurLayer({
        //   layer: WetDogWeatherLayersEnum.WINDGUST,
        //   mode: 'current'
        // }))
        break
      case 'forecast-aviation-wind-gust':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.WINDGUST,
            mode: 'forecast',
          })
        )
        break
      case 'alerts-menu':
        addToTWS(<AlertListBox key="alerts-menu" />)
        break
      case 'aerox-menu':
        addToTWS(<AeroxLayer key="aerox" />)
        break
      // case 'forecast-aviation-wind-gust':
      //   dispatch(setCurLayer({
      //     layer: WetDogWeatherLayersEnum.WINDGUST,
      //     mode: 'forecast'
      //   }))
      //   break
      default:
        break
    }
    if (tileProducts.includes(id)) {
      setCurrTile(id)
      setCurrTileName(selection?.label)
      // dispatch(setActiveBaseLayer('maps-road')) //
    }
  }, [id, selectedItems, eventCount, selectedItemIds])

  return null
}

export default MenuSelection
